import React, { useEffect, useState } from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const Layout = ({ children }) => {
	let [blogPathName, setblogPathName] = useState(false);

	let pathSplit, blogPage;
	useEffect(() => {
		pathSplit = window.location.pathname.split('/');
		blogPage = pathSplit.slice(-2)[0] !== 'blogs';
		setblogPathName(
			window.location.pathname.includes('/blogs/') && blogPage,
		);
	});

	const renderChild = blogPathName ? (
		<div className='flex my-4 justify-between w-11/12 md:w-10/12 max-w-[1300px] mx-auto gap-x-8 md:flex-row flex-col-reverse'>
			<Sidebar />
			{children}
		</div>
	) : (
		children
	);

	return (
		<React.Fragment>
			<GlobalStyles />
			<Navbar />
			{renderChild}
			<Footer />
		</React.Fragment>
	);
};

export default Layout;
