import React from 'react';
import { menuItems } from '../../constants/navData';
import { Link } from 'gatsby';
import { SlArrowRightCircle } from 'react-icons/sl';

export default function MobileMenu({
	setIsNavOpen,
	isNavOpen,
	currentLocation,
}) {
	return (
		<nav
			className={`md:hidden z-[1002] fixed top-20 right-0 w-full bg-white/80 backdrop-blur-lg h-screen flex items-start justify-center ease duration-300 transform ${
				isNavOpen ? 'translate-y-0' : '-translate-y-[120%]'
			}`}
		>
			<ul className='w-full'>
				{menuItems.map((item) => (
					<li key={item.path} className={`group w-full`}>
						<Link
							onClick={() => setIsNavOpen(false)}
							to={
								item.path.includes('#')
									? `/${item.path}`
									: item.path
							}
							className={`text-[#64718A] flex text-2xl font-medium tracking-wider py-4 group-hover:text-black before-link w-full px-12 ${
								item.path === currentLocation
									? 'bg-gray-200 text-black'
									: ''
							}`}
						>
							{item.title}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
}
