import React from 'react';
import { Link } from 'gatsby';

const SidebarCard = ({ item, active }) => {
	return (
		<Link
			className={`mt-8 flex justify-between rounded-2xl bg-[#F8F8FA] ${active} mb-6 p-2 hover:shadow-md transition-all duration-300 ease-in-out group ${
				active ? '' : ''
			}`}
			to={'/blogs/' + item.slug}
		>
			<div className='w-4/12 p-2 flex items-center'>
				<img
					className='object-cover rounded-lg md:h-full'
					src={item.image}
					alt='blog featured'
				/>
			</div>
			<div className='w-8/12 p-2 overflow-hidden'>
				<h2
					className={`text-base text-black font-bold group-hover:text-[#6CB132] ${
						active ? 'text-[#6CB231]' : ''
					}`}
				>
					{item.title}
				</h2>
				<p className='text-sm] text-[#626262] leading-5 mt-2'>
					{item.pretext.split(' ').slice(0, 9).join(' ')}...
				</p>
			</div>
		</Link>
	);
};

export default SidebarCard;
