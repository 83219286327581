import React from 'react';

import HeaderLogo from '../../images/logo.webp';
import { Link } from 'gatsby';

const Logo = () => (
	<Link className='' to='/'>
		<img className='w-32 md:w-40' src={HeaderLogo} alt='logo' />
	</Link>
);

export default Logo;
