import React, { useState } from 'react';
import { blogLinks } from '../constants/blogLinks';
import SidebarCard from './SidebarCard';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Pagination from './Pagination';
import { useEffect } from 'react';

const Sidebar = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const blogLinksRev = [...blogLinks].reverse();
	const indexOfLastPost = currentPage * itemsPerPage;
	const indexOfFirstPost = indexOfLastPost - itemsPerPage;
	const currentPosts = blogLinksRev.slice(
		indexOfFirstPost,
		indexOfLastPost,
	);
	const lastPage = Math.ceil(blogLinks.length / itemsPerPage);

	useEffect(() => {
		if (localStorage.getItem('page'))
			setCurrentPage(parseInt(localStorage.getItem('page')));
	});

	const setPage = (pageNum) => {
		setCurrentPage(pageNum);
		localStorage.setItem('page', pageNum);
		if (window) {
			window.scrollTo(0, 0);
		}
	};

	const handleClick = (index) => {
		setActiveIndex(index);
		if (window) {
			window.scrollTo(0, 0);
		}
	};

	const renderedObjects = currentPosts.map((item, index) => {
		return (
			<div
				key={index}
				role='presentation'
				onClick={() => handleClick(index)}
			>
				<SidebarCard
					index={index}
					item={item}
					active={index === activeIndex ? 'active' : ''}
				/>
			</div>
		);
	});
	return (
		<div className='w-full md:w-4/12 mt-8 md:mt-0 mb-16 md:mb-0'>
			<div className='text-2xl flex justify-center'>
				<button
					className={`w-8 h-8 font-bold text-gray-500 hover:bg-gray-200 rounded-l-full ${
						currentPage === 1 ? 'disabled' : ''
					}`}
					onClick={() => setPage(currentPage - 1)}
					disabled={currentPage === 1}
				>
					<FiChevronLeft />
				</button>
				<Pagination
					currentPage={currentPage}
					itemsPerPage={itemsPerPage}
					totalItems={blogLinks.length}
					onPageChange={setPage}
				/>
				<button
					className={`w-8 h-8 font-bold text-gray-500 hover:bg-gray-200 rounded-r-full ${
						currentPage === lastPage ? 'disabled' : ''
					}`}
					onClick={() => setPage(currentPage + 1)}
					disabled={currentPage === lastPage}
				>
					<FiChevronRight />
				</button>
			</div>
			{renderedObjects}
			<div className='text-2xl flex justify-center'>
				<button
					className={`w-8 h-8 font-bold text-gray-500 rounded-l-full ${
						currentPage === 1 ? 'disabled' : ''
					}`}
					onClick={() => setPage(currentPage - 1)}
					disabled={currentPage === 1}
				>
					<FiChevronLeft />
				</button>
				<Pagination
					currentPage={currentPage}
					itemsPerPage={itemsPerPage}
					totalItems={blogLinks.length}
					onPageChange={setPage}
				/>
				<button
					className={`w-8 h-8 font-bold text-gray-500 rounded-r-full ${
						currentPage === lastPage ? 'disabled' : ''
					}`}
					onClick={() => setPage(currentPage + 1)}
					disabled={currentPage === lastPage}
				>
					<FiChevronRight />
				</button>
			</div>
		</div>
	);
};

export default Sidebar;
