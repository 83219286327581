import React, { useEffect, useState } from 'react';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import { SlMenu } from 'react-icons/sl';
import Logo from './Logo';
import { IoCloseOutline } from 'react-icons/io5';

export default function Navbar() {
	let [currentLocation, setCurrentLocation] = useState('');

	useEffect(() => {
		setCurrentLocation(
			window.location.hash || window.location.pathname,
		);
	});

	const [isNavOpen, setIsNavOpen] = useState(false);
	function toggleMenu() {
		setIsNavOpen(!isNavOpen);
	}

	return (
		<nav className='w-full h-32 md:h-24'>
			<div className='w-11/12 md:w-10/12 xl:max-w-[1300px] mx-auto h-32 md:h-24'>
				<div className='flex justify-between items-center h-20 md:h-24'>
					<div className='logo'>
						<Logo />
					</div>
					<div>
						<div className=''>
							<button
								onClick={toggleMenu}
								className='text-black text-xl md:hidden'
								aria-label='Action Button'
							>
								{isNavOpen ? (
									<IoCloseOutline />
								) : (
									<SlMenu />
								)}
							</button>
						</div>
						<DesktopMenu
							currentLocation={currentLocation}
						/>
						<MobileMenu
							isNavOpen={isNavOpen}
							setIsNavOpen={setIsNavOpen}
							currentLocation={currentLocation}
						/>
					</div>
				</div>
			</div>
		</nav>
	);
}
