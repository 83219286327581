import React from 'react';
import { footerData } from '../constants/footerData';

import FooterLogo from '../images/Footer/logo.webp';
import { Link } from 'gatsby';
import { FaFacebook } from 'react-icons/fa';
import { AiFillInstagram, AiFillTwitterCircle } from 'react-icons/ai';

const Footer = () => {
	return (
		<footer className='bg-[#071731]'>
			{/* <div
        style={{
          backgroundImage: `url(${DishImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-[30rem] flex items-center justify-center hidden"
      >
        <div className="rounded-lg md:w-3/4 my-0 mx-auto flex flex-col md:flex-row justify-between items-center bg-[#000000CC] py-8">
          <div className="w-full md:w-1/2 px-8">
            <h2 className="text-4xl font-[500] mb-4 text-center md:text-left">
              Foodaholix Newsletter
            </h2>
            <p className="text-2xl text-white text-center md:text-left">
              Sign Up to our Newsletter to get fun updates on our app and future
              releases
            </p>
          </div>
          <div className="w-full md:w-1/2 px-8 my-8">
            <form className="flex">
              <input
                className="w-2/3 border-0 outline-none rounded-tl-md rounded-bl-md text-gray-800 text-2xl px-4"
                id="subscribe"
              />
              <label hidden htmlFor="subscribe">
                Subscribe
              </label>
              <button className="cursor-pointer py-4 px-12 bg-[#F1A73C] transition-all ease-in-out duration-300 text-2xl rounded-tr-md rounded-br-md">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div> */}
			<div className='w-11/12 md:w-10/12 max-w-[1300px]  mx-auto h-full py-12 grid md:grid-cols-2'>
				<div className='w-full flex flex-col items-center md:items-start'>
					<img
						className='h-auto w-64'
						src={FooterLogo}
						alt='footer logo'
					/>
					<p className='max-w-96 font-[400] mt-8 text-center md:text-start'>
						Foodaholix helps you effortlessly discover,
						rate, and explore the best dining spots near
						you. Your next great business meal is just a
						tap away!
					</p>
					<div className='flex gap-4 mt-4'>
						<a href='https://www.facebook.com/foodaholixworld'>
							<FaFacebook className='w-8' />
						</a>
						<a href='https://twitter.com/FoodaholixWorld'>
							<AiFillTwitterCircle className='w-8' />
						</a>
						<a href='https://www.instagram.com/foodaholixworld/#'>
							<AiFillInstagram className='w-8' />
						</a>
					</div>
				</div>
				<div className='grid grid-cols-2 md:grid-cols-3 gap-x-4 mt-16 md:mt-0'>
					<div className='w-full flex flex-col'>
						<h3 className='text-xl font-bold text-[#F1A73C] mb-1 mt-6'>
							{footerData.columnThree.title}
						</h3>
						{footerData.columnThree.menuItems.map(
							(item) => (
								<Link
									key={item.title}
									to={
										item.link.includes(
											'#',
										)
											? `/${item.link}`
											: `${item.link}`
									}
									className='text-gray-500 hover:text-white my-1 transition-all duration-300 ease-in-out'
								>
									{item.title}
								</Link>
							),
						)}
						<h3 className='text-lg font-[500] text-white mb-1 mt-6 hidden'>
							{footerData.columnFour.title}
						</h3>
						{/* footerData.columnFour.menuItems.map((item) => (
              <Link
                key={item.title}
                to={item.link}
                className="text-gray-500 hover:text-white my-1 transition-all duration-300 ease-in-out"
              >
                {item.title}
              </Link>
            )) */}
					</div>
					<div className='w-full flex flex-col'>
						<h3 className='text-lg font-bold text-[#F1A73C] mb-1 mt-6'>
							{footerData.columnTwo.title}
						</h3>
						<p className='text-gray-500 pb-4'>
							{footerData.columnTwo.address}
						</p>
						<p className='text-gray-500 pb-4 text-wrap hidden'>
							{footerData.columnTwo.email}
						</p>
					</div>
					<div className='w-full flex flex-col'>
						<h3 className='text-lg font-bold text-[#F1A73C] mb-4 mt-6'>
							{footerData.columnFive.title}
						</h3>
						<div className='flex flex-wrap gap-4'>
							{footerData.columnFive.icons.map(
								(item) => (
									<a
										key={
											Math.random() *
											1000000000
										}
										rel='noreferrer'
										href={item.link}
										aria-label={
											item.name
										}
										target='_blank'
										className='w-6 h-6 flex justify-center items-center'
									>
										{item.icon}
									</a>
								),
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='mt-4 text-gray-500 py-2 text-center w-full bg-black'>
				&copy; Copyright @ 2025 Uideas Solutions Inc. All Rights
				Reserved.
			</div>
		</footer>
	);
};

export default Footer;
