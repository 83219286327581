import React from 'react';
import { menuItems } from '../../constants/navData';
import { Link } from 'gatsby';

export default function DesktopMenu({ currentLocation }) {
	return (
		<nav className='hidden md:block'>
			<ul className='flex justify-center items-center'>
				{menuItems.map((item) => (
					<li
						key={item.path}
						className={`group relative nav-link mx-4 ${
							item.path === currentLocation
								? 'active'
								: ''
						}`}
					>
						<Link
							to={
								item.path.includes('#')
									? `/${item.path}`
									: item.path
							}
							className={`text-black inline-block text-lg font-normal tracking-wider py-1 group-hover:text-[#6CB132] before-link`}
						>
							{item.title}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
}
