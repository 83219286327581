import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { RiPinterestFill } from "react-icons/ri";
import AppleLogo from "../images/Footer/apple.webp";
import PlayStoreLogo from "../images/Footer/google.webp";

export const footerData = {
  columnOne: {
    imageDir: "../images/icon.png",
  },
  columnTwo: {
    title: "Contact Us",
    address: "2700 Mission College Blvd #140 #256, Santa Clara, CA 95054",
    email: "admin@foodaholix.com",
  },
  columnThree: {
    title: "More",
    menuItems: [
      {
        title: "Home",
        link: "/",
      },
      {
        title: "About Us",
        link: "#about",
      },
      {
        title: "Contact Us",
        link: "#contact",
      },
      // {
      //   title: "Services",
      //   link: "#services",
      // },
      {
        title: "Blogs",
        link: "/blogs",
      },
    ],
  },
  columnFour: {
    title: "Policies",
    menuItems: [
      {
        title: "Eula",
        link: "/EULA",
      },
      {
        title: "Privacy Policy",
        link: "/PrivacyPolicy",
      },
    ],
  },
  columnFive: {
    title: "Download Foodaholixur App",
    icons: [
      {
        name: 'Apple Store',
        icon: <img src={AppleLogo} alt="apple-logo" />,
        link: "https://apps.apple.com/us/app/foodaholix/id1587747643",
      },
      {
        name: 'Google Play',
        icon: <img src={PlayStoreLogo} alt="play-store-logo" />,
        link: "https://play.google.com/store/apps/details?id=com.uideas.foodaholix",
      },
    ],
  },
  columnSix: {
    title: "Follow Us",
    icons: [
      {
        name: 'Instagram',
        icon: <AiFillInstagram />,
        link: "https://www.instagram.com/foodaholixworld",
      },
      {
        name: 'Facebook',
        icon: <FaFacebookF />,
        link: "https://www.facebook.com/foodaholixworld",
      },
      {
        name: 'Facebook',
        icon: <BsTwitter />,
        link: "https://twitter.com/FoodaholixWorld",
      },
      {
        name: 'Facebook',
        icon: <RiPinterestFill />,
        link: "https://www.pinterest.com/foodaholixworld/",
      },
    ],
  },
};
